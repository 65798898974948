import React from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Slice
import { setSearchType } from '../../app/features/librarySlice';
// Styles
import './MetasearcherAdvanced.css';

const MetasearcherAdvanced = ({ route }) => {
  const dispatch = useDispatch();

  const { searchType } = useSelector((state) => state.library);

  if (route === 'articles') {
    return (
      <div className='metasearchOptions'>
        <div className='metasearchOptionsButtons'>
          <button
            className={
              searchType === 'simple' ? 'buttonOption active' : 'buttonOption'
            }
            onClick={() => dispatch(setSearchType('simple'))}
          >
            Simple
          </button>
          <button
            className={
              searchType === 'advanced' ? 'buttonOption active' : 'buttonOption'
            }
            onClick={() => dispatch(setSearchType('advanced'))}
          >
            Advance
          </button>
        </div>
        <button
          className={
            searchType === 'ia' ? 'generateIAButton active' : 'generateIAButton'
          }
          onClick={() => dispatch(setSearchType('ia'))}
        >
          Generate search with AI
        </button>
      </div>
    );
  }
};

export default MetasearcherAdvanced;
