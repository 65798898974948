import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Modal from 'react-modal';
import App from './App';
// Axios
import axios from 'axios';
// Sentry
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
// WebVitals
import reportWebVitals from './reportWebVitals';
// Styles
import './index.css';

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

axios.defaults.baseURL =
  ENVIRONMENT === 'PRODUCTION'
    ? 'https://olib-api.aquinasnetwork.com'
    : 'https://olib-api.aquinasnetwork.com';
// : 'http://localhost:8000';

// Solo inicializar Sentry en entorno de desarrollo
if (ENVIRONMENT === 'PRODUCTION') {
  Sentry.init({
    dsn: 'https://32622f1a4d47110a9254c4aebea89f2e@o4505720840716288.ingest.sentry.io/4505720845828096',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
Modal.setAppElement('#root'); // Configura el elemento raíz de la aplicación

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console .log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
