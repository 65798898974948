import React from 'react';
import { Link } from 'react-router-dom';
// Component
import Navbar from '../Navbar';
import Login from '../Login';
import Container from '../Container';
// Images
import logoHeaderHome from '../../Images/logo/logo_OlibWhite.svg';
// Style
import './Header.css';

const Header = () => {
  return (
    <header>
      <Container>
        <div className='header'>
          <Link to='/'>
            <button className='navigationList__linkToHome'>
              <img src={logoHeaderHome} alt='logo' />
            </button>
          </Link>
          <div className='navbarAndLoginContainer'>
            <Navbar type='white' />
            <Login />
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
