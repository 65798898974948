import { useDispatch, useSelector } from 'react-redux';
// Slices
import { setSocketBooks } from '../app/features/metasearchSlice';
import {
  setIsBooksLoading,
  setIsBooksLoadingMoreData,
  setBooks,
  // setBooksKeywords,
  setBooksSources,
  setTotalBooks,
  setSelectedSources,
  // Data fetched
  setQueryFetched,
  setIsBooksDataFetched,
  setSourcesFetched,
  setKeywordsFetched,
  setStartYearFetched,
  setEndYearFetched,
  setPageFetched,
  // reset variables
  resetBooksSources,
  resetBooksKeywords,
  resetBooks,
  setPageShowed,
  setStartYear,
  setEndYear,
} from '../app/features/booksSlice';

export const useBooks = () => {
  const dispatch = useDispatch();

  // Slices
  const metaSearchStore = useSelector((state) => state.metasearch);
  const { user_email, ip_login } = useSelector((state) => state.user);
  const { institutionCode } = useSelector((state) => state.institution);

  // Función para guar las variables en Redux
  const saveInformationRedux = (
    query,
    page,
    sources,
    keywords,
    startYear,
    endYear
  ) => {
    if (page === 1) {
      dispatch(resetBooksKeywords());
      dispatch(resetBooks());
      dispatch(setTotalBooks(0));
      dispatch(setPageShowed(1));
      dispatch(setIsBooksLoading(true));
      dispatch(setIsBooksLoadingMoreData(true));
      dispatch(setSelectedSources(sources));
      dispatch(resetBooksSources());
    }
    // Guardando datos buscados en las variables (Fetched)
    dispatch(setQueryFetched(query));
    dispatch(setIsBooksDataFetched(true));
    dispatch(setSourcesFetched(sources));
    dispatch(setKeywordsFetched(keywords));
    dispatch(setStartYear(startYear));
    dispatch(setStartYearFetched(startYear));
    dispatch(setEndYear(endYear));
    dispatch(setEndYearFetched(endYear));
    dispatch(setPageFetched(page));
  };

  const getBooksDataSocket = async (
    query = '',
    page = 1,
    startYear = 1990,
    endYear = 2024,
    sources = [],
    keywords = []
  ) => {
    // Controlador de conteo en respuesta Single del Websocket
    let totalBooksSingle = 0;
    // Guarda la información de la busqueda en Redux
    saveInformationRedux(query, page, sources, keywords, startYear, endYear);

    // url de la conexión al websocket
    // const url = `ws://localhost:8000/search?auth_token=${localStorage.token}&version=v2`; // local
    // const url = `ws://5.161.61.25:8002/search?auth_token=${localStorage.token}&version=v2`; // Servidor de pruebas
    const url = `wss://olib-api.aquinasnetwork.com/search?auth_token=${localStorage.token}&version=v2`;

    // Verifica si hay una conexión anterior y la cierra
    if (metaSearchStore && metaSearchStore.socketBooks instanceof WebSocket) {
      metaSearchStore.socketBooks.close();
    }

    // Se crea la configuración para la conexión al web socket
    const websocketBooks = new WebSocket(url);

    // Se crea el nuevo estado para la variable "socket" que verifica si hay o no una conexión previa
    dispatch(setSocketBooks(websocketBooks));

    // Envia la petición al web socket para iniciar la conexión
    websocketBooks.onopen = function () {
      const socketQuery = {
        query,
        page,
        cache: false,
        user_email,
        ip_login,
        institution_code: institutionCode,
        params: {
          start_year: startYear,
          end_year: endYear,
        },
        source_type: 'books',
      };
      // Adding filters in query
      if (sources?.length > 0) {
        socketQuery.params.sources = sources;
      }
      if (keywords?.length > 0) {
        socketQuery.params.keywords = keywords;
      }
      websocketBooks.send(JSON.stringify(socketQuery));
    };

    // Recibe la información del websocketBooks
    websocketBooks.onmessage = function (event) {
      const dataSource = JSON.parse(event.data);

      if (dataSource.type === 'summary') {
        if (dataSource.count >= 0) {
          dispatch(setTotalBooks(dataSource?.count));
          dispatch(setIsBooksLoading(false));
          dispatch(setIsBooksLoadingMoreData(false));
          websocketBooks.close();
        }
      }

      if (dataSource.type === 'single') {
        dispatch(setBooks([...dataSource.data.results]));
        const source_info = {
          name: dataSource.data.source_code.replace('_', ' '),
          code: dataSource.data.source_code,
          count: dataSource.data.count,
        };
        totalBooksSingle = totalBooksSingle + dataSource.data.count;
        dispatch(setTotalBooks(totalBooksSingle));

        page === 1 && dispatch(setBooksSources(source_info));
        dataSource.data.count > 0 && dispatch(setIsBooksLoading(false));
      }
    };

    // Cierre de la conexión por tiempo
    setTimeout(() => {
      dispatch(setIsBooksLoading(false));
      dispatch(setIsBooksLoadingMoreData(false));
      websocketBooks.close();
    }, 120000);
  };
  return { getBooksDataSocket };
};
