import { fetchWithoutToken } from '../utils/fetch';
import { formatDate } from '../utils/formatDate';
import { getIpUser } from './getIpUser';

// Función para enviar información del usuario para almacenarla en base de datos:
export const sendUserInformation = async (
	searchResourceCategory, // Category to search (Library, media resource, news, dictionary )
	searchedTerm, // Searched Term
	email, // email
	institutionCode
	// ipLogin // user IP
) => {
	const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
	if (ENVIRONMENT === 'PRODUCTION') {
		const date = new Date();
		const ip = await getIpUser();
		try {
			await fetchWithoutToken.post(
				'/logs',
				{
					resource_type: searchResourceCategory.slice(0, 250),
					data: searchedTerm.slice(0, 250),
					email: email ? email.slice(0, 250) : 'No logged user',
					ip,
					institution_code: institutionCode,
					date: formatDate(date),
				},
				{
					headers: {
						Authorization: `Bearer ${localStorage.token}`,
					},
				}
			);
		} catch (e) {
			console.error(e);
		}
	} else {
		return null;
	}
};
