import React from 'react';
// Redux
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// Components
import Card from '../../Components/Card';
import Container from '../../Components/Container';
import Pagination from '../../Components/Pagination';
import Instructions from '../../Components/Instructions';
import MetasearcherAdvanced from '../../Components/MetasearcherAdvanced';
import SummarySearch from '../../Components/SummarySearch';
import FiltersLibrary from '../../Components/FiltersLibrary';
import { LoadingSkeleton } from '../../Components/LoadingSkeleton';
import { SkeletonTypes } from '../../Components/LoadingSkeleton/types';
// Slices
import { setPageShowed } from '../../app/features/librarySlice';
// Hooks
import { useLibrary } from '../../hooks/useLibrary';
import { useScrollToTop } from '../../hooks/useScrollToTop';
// Utils
import { addLibraryData } from '../../utils/addLibraryData';
// Styles
import './styles.css';

const LibraryPage = () => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const currentlyRoute = pathname.replace('/', '');
  // Hooks
  const { getLibraryDataSocket } = useLibrary();
  const { handleScrollToTop } = useScrollToTop();

  // Slices
  const { institutionCode } = useSelector((state) => state.institution);
  const {
    data = [],
    filters,
    pageShowed,
    isLibraryLoading,
    totalData,
    queryFetched = '',
    queryAdvancedFetched = '',
    tokenPage,
    searchType,
  } = useSelector((state) => state.library);

  // Pagination
  const itemsPerPage = 10;
  const indexOfLastItem = pageShowed * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    data.length > 0
      ? addLibraryData(data, queryFetched, institutionCode).slice(
          indexOfFirstItem,
          indexOfLastItem
        )
      : [];
  const totalPages = Math.ceil(totalData / itemsPerPage);

  const handlePageChange = (newPage) => {
    handleScrollToTop(0);
    if (newPage > pageShowed) {
      if (
        pageShowed < totalPages ||
        (Math.ceil(data?.length / itemsPerPage) > 1 && totalPages === 0)
      ) {
        dispatch(setPageShowed(newPage));
        if (newPage >= Math.ceil(data?.length / itemsPerPage) - 1) {
          getLibraryDataSocket(
            filters.advancedSearchFetched ? queryAdvancedFetched : queryFetched,
            filters.pageFetched + 1,
            filters.peerReviewedFetched,
            filters.startYearFetched,
            filters.endYearFetched,
            filters.sourcesFetched,
            filters.keywordsFetched,
            filters.advancedSearchFetched,
            tokenPage,
            filters.generativeIAFetched
          );
        }
      }
    } else {
      if (pageShowed > 1) {
        dispatch(setPageShowed(newPage));
      }
    }
  };

  return (
    <>
      <div className='metasearchAdvanceContainerArticles'>
        <MetasearcherAdvanced route={currentlyRoute} />
      </div>
      <Instructions searchType={searchType} />
      {isLibraryLoading && (
        <main
          className={
            searchType !== 'simple'
              ? 'library-content noSimple'
              : 'library-content'
          }
        >
          <Container>
            <section className='results'>
              {Array.from({ length: 10 }).map((_, i) => (
                <LoadingSkeleton key={i} type={SkeletonTypes.LIBRARY_CARD} />
              ))}
            </section>
          </Container>
        </main>
      )}

      {data?.length > 0 ? (
        <main
          className={
            searchType !== 'simple'
              ? 'library-content noSimple'
              : 'library-content'
          }
        >
          <Container>
            <div className='metasearchLibraryContainer__info'>
              <SummarySearch total={totalData} />
              <FiltersLibrary />
            </div>

            <section>
              <div className='results'>
                {currentItems.map((libraryItem, i) => (
                  <Card
                    key={i}
                    title={libraryItem?.title}
                    authors={libraryItem?.authors}
                    abstract={libraryItem?.abstract}
                    publication_date={libraryItem?.publication_date}
                    url={libraryItem?.url}
                    type={libraryItem?.type}
                    resource_origin={
                      libraryItem?.source_code
                        ? libraryItem?.source_code.replace('_', ' ')
                        : 'No data source'
                    }
                    peer_reviewed={libraryItem?.peer_reviewed}
                  />
                ))}
              </div>
              <Pagination
                pageShowed={pageShowed}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
              />
            </section>
          </Container>
        </main>
      ) : isLibraryLoading === false &&
        totalData === 0 &&
        queryFetched.length === 0 ? (
        <main
          className={
            searchType !== 'simple'
              ? 'noDataContainer noSimple'
              : 'noDataContainer'
          }
        >
          <p>Please enter a search term</p>
        </main>
      ) : (
        <main
          className={
            searchType !== 'simple'
              ? 'noDataContainer noSimple'
              : 'noDataContainer'
          }
        >
          <p>No data found</p>
        </main>
      )}
    </>
  );
};

export default LibraryPage;
