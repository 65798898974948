import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Slices
import {
  setJobFetched,
  setViewApplyJobs,
  setApplyJob,
} from '../../app/features/jobsSlice';
// Services
import { sendUserSearchInformation } from '../../services/sendUserSearchInformation';
// Images
import defaultImg from '../../Images/JobsPage/default.png';
// Styles
import './CardJobs.css';

const CardJobs = ({
  id,
  img,
  title,
  company,
  description,
  date,
  schedule_type,
  job,
  work_from_home,
}) => {
  const dispatch = useDispatch();
  // Slices
  const { user_email } = useSelector((state) => state.user);
  const { institutionCode } = useSelector((state) => state.institution);
  const { jobFetched } = useSelector((state) => state.jobs);

  const handleJobFetched = () => {
    dispatch(setViewApplyJobs(false));
    dispatch(setApplyJob([]));
    dispatch(setJobFetched(job));
    window.scrollTo(0, 0);
    window.scrollTo(0, 400);
    sendUserSearchInformation(
      title,
      'google jobs',
      'No url',
      'job',
      user_email,
      institutionCode
    );
  };

  return (
    <div
      className={id === jobFetched.job_id ? 'cardJob selectedJob' : 'cardJob'}
      onClick={handleJobFetched}
      role='button'
    >
      <div className='cardJob_mainInfo'>
        <img alt='IMG' src={img ? img : defaultImg} />
        <div>
          <h3 className='cardJob_title'>{title}</h3>

          <h4 className='cardJob_company'>{company}</h4>
        </div>
      </div>

      <div className='cardJob_info'>
        <p className='cardJob_description'>{description}...</p>
        <div className='cardJob_date'>
          <p>
            {schedule_type ? schedule_type : ''}
            {work_from_home && ', remote'}
          </p>
          <p>{date && date}</p>
        </div>
        <p>Show more</p>
      </div>
    </div>
  );
};

export default CardJobs;
