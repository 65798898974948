import React, { useState } from 'react';
// Components
import Container from '../Container';
// Utils
import { areListsDiff } from '../../utils/areaListDiff';
import { removeElement } from '../../utils/removeElement';
// Reddux
import { useSelector, useDispatch } from 'react-redux';
// Hooks
import { useGallery } from '../../hooks/useGallery';
import { useScrollToTop } from '../../hooks/useScrollToTop';
// Slices
// import {
// setStartYear,
// setEndYear,
// setSelectedSourcesArt,
// resetFilters,
// setPageShowed,
// } from '../../app/features/booksSlice';
// Slices
import {
  setPeriodFetched,
  setPeriodSelected,
  setSelectedStartYear,
  setSelectedEndYear,
  setSelectedSourcesArt,
  setSelectedColor,
  // setOnlyImage,
  // setOnlyVideo,
  // setOnlyAudio,
  // resetFilters,
} from '../../app/features/artSlice';
// Icons
import { IoMdOptions } from 'react-icons/io';
import { TbChevronUp } from 'react-icons/tb';
import { IoMdClose } from 'react-icons/io';
// Styles
import './FiltersArt.css';

const FiltersArt = () => {
  const dispatch = useDispatch();

  const { handleScrollToTop } = useScrollToTop();
  const { getGalleryDataSocket } = useGallery();

  // Variables para visualización de filtros
  const [isOpenSource, setIsOpenSource] = useState(false);
  const [isOpenYears, setIsOpenYears] = useState(false);
  const [isOpenColors, setIsOpenColors] = useState(false);
  const [showAllPeriods, setShowAllPeriods] = useState(false);
  // const [numColors, setNumColors] = useState(12);

  const [activeFilter, setActiveFilter] = useState(false);

  // Slices
  const {
    colors,
    selectedSourcesArt,
    periodSelected,
    selectedStartYear,
    selectedEndYear,
    selectedColor,
    onlyImage,
    onlyVideo,
    onlyAudio,
    filters,
  } = useSelector((state) => state.art);

  const allArtSources = [
    'NAGUS',
    'METMUSEUM',
    'HARVARDMUSEUM',
    'COOPERMUSEUM',
    'RIJKSMUSEUM',
    'DPLA',
    'DIGITALNZ',
  ];

  // Función que selecciona las bases de datos
  const sourcesSelected = (optionValue) => {
    if (selectedSourcesArt.includes(optionValue)) {
      dispatch(
        setSelectedSourcesArt(
          selectedSourcesArt.filter((value) => value !== optionValue)
        )
      );
    } else {
      dispatch(setSelectedSourcesArt([...selectedSourcesArt, optionValue]));
    }
  };

  const handleApplyFilters = () => {
    handleScrollToTop(0);
    dispatch(setPeriodFetched(periodSelected));
    getGalleryDataSocket(
      filters.queryFetched,
      1,
      selectedStartYear,
      selectedEndYear,
      selectedSourcesArt,
      selectedColor,
      onlyImage,
      onlyVideo,
      onlyAudio,
      periodSelected
    );
  };

  const yearSelected = (artPeriod) => {
    if (artPeriod.name === periodSelected.name) {
      dispatch(setPeriodSelected(''));
      dispatch(setSelectedStartYear(''));
      dispatch(setSelectedEndYear(''));
    } else {
      dispatch(setPeriodSelected(artPeriod));
      dispatch(setSelectedStartYear(artPeriod.years.split('-')[0]));
      dispatch(setSelectedEndYear(artPeriod.years.split('-')[1]));
    }
  };

  const getArtPeriods = () => {
    const artPeriods = [
      { name: 'Byzantine', years: '330-1453' },
      { name: 'Romanesque', years: '1000-1150' },
      { name: 'Gothic', years: '1140-1600' },
      { name: 'Renaissance', years: '1495-1527' },
      { name: 'Mannerism', years: '1520-1600' },
      { name: 'Baroque', years: '1600-1725' },
      { name: 'Rococo', years: '1720-1760' },
      { name: 'Neoclassicism', years: '1770-1840' },
      { name: 'Romanticism', years: '1800-1850' },
      { name: 'Realism', years: '1840-1870' },
      { name: 'Pre-Raphaelite', years: '1848-1854' },
      { name: 'Impressionism', years: '1870-1900' },
      { name: 'Naturalism', years: '1880-1900' },
      { name: 'Symbolism', years: '1886-1900' },
      { name: 'Post-Impressionism', years: '1886-1905' },
      { name: 'Expressionism', years: '1890-1939' },
      { name: 'Art Nouveau', years: '1895-1915' },
      { name: 'Cubism', years: '1905-1939' },
      { name: 'Futurism', years: '1909-1918' },
      { name: 'Dadaism', years: '1912-1923' },
      { name: 'Constructivism', years: '1913-1930' },
      { name: 'New Objectivity', years: '1918-1933' },
      { name: 'Harlem Renaissance', years: '1920-1930' },
      { name: 'Precisionism', years: '1920-1950' },
      { name: 'Art Deco', years: '1920-1935' },
      { name: 'Bauhaus', years: '1920-1925' },
      { name: 'Surrealism', years: '1924-1945' },
      { name: 'Abstract Expressionism', years: '1945-1960' },
      { name: 'Pop Art', years: '1956-1969' },
      { name: 'Arte Povera', years: '1960-1969' },
      { name: 'Minimalism', years: '1960-1975' },
      { name: 'Op Art', years: '1965-1970' },
      { name: 'Photorealism', years: '1968-2024' },
      { name: 'Lowbrow Pop Surrealism', years: '1970-2024' },
      { name: 'Contemporary art', years: '1978-2024' },
    ];
    return showAllPeriods ? artPeriods : artPeriods.slice(0, 5);
  };

  // Funcion para Borrar filtros
  const handleClearFilters = () => {
    handleScrollToTop(0);
    getGalleryDataSocket(
      filters.queryFetched,
      1,
      '',
      '',
      allArtSources,
      '',
      false,
      false,
      false,
      ''
    );
  };

  return (
    <>
      <div className='filtersBarIcon'>
        <b>Filters</b>
        <button onClick={() => setActiveFilter(true)}>
          <IoMdOptions className='optionsIcon' />
        </button>
      </div>

      <section
        className={
          activeFilter ? 'sectionFilter activeFilter' : 'sectionFilter'
        }
      >
        <div
          onClick={() => setActiveFilter(false)}
          className={
            activeFilter
              ? 'filterBarBackground activeFilter'
              : 'filterBarBackground'
          }
        ></div>
        <div className='filtersBar'>
          <Container className='filtersBarMain'>
            <button onClick={() => setActiveFilter(false)}>
              <IoMdClose />
            </button>
            <div>
              <IoMdOptions className='optionsIcon' />
              <h2>Filters</h2>
            </div>
          </Container>

          {(filters?.periodFetched !== '' ||
            areListsDiff(filters.sourcesFetchedArt, allArtSources) ||
            filters?.colorsFetched !== '') && (
            <Container className='filtersBarSection'>
              <div className='filtersBarSection__main'>
                <h3>Filters</h3>
                <button onClick={handleClearFilters}>Remove all filters</button>
              </div>
              <div className='filtersBarSectionFilter__content'>
                {filters?.periodFetched !== '' && (
                  <button
                    onClick={() =>
                      getGalleryDataSocket(
                        filters.queryFetched,
                        1,
                        selectedStartYear,
                        selectedEndYear,
                        selectedSourcesArt,
                        selectedColor,
                        onlyImage,
                        onlyVideo,
                        onlyAudio,
                        ''
                      )
                    }
                  >
                    {`Period: ${filters?.periodFetched.name} (${filters?.periodFetched.years})`}{' '}
                    <IoMdClose />
                  </button>
                )}
                {filters?.colorsFetched !== '' && (
                  <button
                    onClick={() =>
                      getGalleryDataSocket(
                        filters.queryFetched,
                        1,
                        selectedStartYear,
                        selectedEndYear,
                        selectedSourcesArt,
                        '',
                        onlyImage,
                        onlyVideo,
                        onlyAudio,
                        periodSelected
                      )
                    }
                  >
                    Selected color:
                    <div
                      role='button'
                      style={{
                        height: '25px',
                        width: '25px',
                        borderRadius: '50%',
                        backgroundColor: filters?.colorsFetched,
                      }}
                    ></div>
                    <IoMdClose />
                  </button>
                )}

                {areListsDiff(filters.sourcesFetchedArt, allArtSources) &&
                  filters.sourcesFetchedArt.map((source, i) => (
                    <button
                      key={i}
                      onClick={() =>
                        getGalleryDataSocket(
                          filters.queryFetched,
                          1,
                          selectedStartYear,
                          selectedEndYear,
                          removeElement(selectedSourcesArt, source),
                          selectedColor,
                          onlyImage,
                          onlyVideo,
                          onlyAudio,
                          periodSelected
                        )
                      }
                    >
                      {source.replace('_', ' ')} <IoMdClose />
                    </button>
                  ))}
              </div>
            </Container>
          )}

          <Container className='filtersBarSection'>
            <div
              className='filtersBarSection__main'
              onClick={() => setIsOpenSource(!isOpenSource)}
              role='button'
            >
              <h3>Sources</h3>

              <button>
                <TbChevronUp className={isOpenSource ? '' : 'iconActive'} />
              </button>
            </div>
            {isOpenSource && (
              <div className='filtersBarSection__content fixed'>
                {allArtSources.map((source, i) => {
                  return (
                    <button
                      key={i}
                      className={
                        selectedSourcesArt.includes(source) ? 'selected' : ''
                      }
                      onClick={() => sourcesSelected(source)}
                    >
                      {source.replace('_', ' ')}
                    </button>
                  );
                })}
              </div>
            )}
          </Container>

          <Container className='filtersBarSection'>
            <div
              className='filtersBarSection__main'
              onClick={() => setIsOpenYears(!isOpenYears)}
              role='button'
            >
              <h3>Year</h3>
              <button>
                <TbChevronUp className={isOpenYears ? '' : 'iconActive'} />
              </button>
            </div>
            {isOpenYears && (
              <div className='filterOptionsContainer'>
                {filters.periodFetched && (
                  <label className='labelText'>
                    <input
                      checked={
                        periodSelected.name === filters.periodFetched.name ||
                        (filters.periodFetched.years.split('-')[0] ===
                          selectedStartYear &&
                          filters.periodFetched.years.split('-')[1] ===
                            selectedEndYear)
                          ? true
                          : false
                      }
                      type='checkbox'
                      className='labelBox'
                      onChange={() => {
                        yearSelected(filters.periodFetched);
                      }}
                    />
                    {filters.periodFetched.name} ({filters.periodFetched.years})
                  </label>
                )}

                {getArtPeriods().map((artPeriod, i) => {
                  if (artPeriod.name !== filters.periodFetched.name) {
                    return (
                      <label className='labelText' key={i}>
                        <input
                          checked={
                            periodSelected.name === artPeriod.name ||
                            (artPeriod.years.split('-')[0] ===
                              selectedStartYear &&
                              artPeriod.years.split('-')[1] === selectedEndYear)
                              ? true
                              : false
                          }
                          type='checkbox'
                          className='labelBox'
                          onChange={() => {
                            yearSelected(artPeriod);
                          }}
                        />{' '}
                        {artPeriod.name} ({artPeriod.years})
                      </label>
                    );
                  } else return null;
                })}
                <button
                  className='showArtPeriodsButton'
                  onClick={() => setShowAllPeriods(!showAllPeriods)}
                >
                  {showAllPeriods
                    ? 'Show less art periods'
                    : 'Show all art periods'}
                </button>
              </div>
            )}
          </Container>

          {colors && (
            <Container className='filtersBarSection'>
              <div
                className='filtersBarSection__main'
                onClick={() => setIsOpenColors(!isOpenColors)}
                role='button'
              >
                <h3>Colors</h3>

                <button>
                  <TbChevronUp className={isOpenColors ? '' : 'iconActive'} />
                </button>
              </div>
              {isOpenColors && (
                <div className='filtersBarSection__content fixed'>
                  {colors?.slice(0, 18).map((color, i) => {
                    if (color !== filters.colorsFetched) {
                      return (
                        <div
                          key={i}
                          className={
                            selectedColor === color ? 'selectedColorBorder' : ''
                          }
                        >
                          <div
                            key={i}
                            className={
                              selectedColor === color ? 'selectedColor' : ''
                            }
                            role='button'
                            onClick={() => dispatch(setSelectedColor(color))}
                            style={{
                              height: selectedColor === color ? '21px' : '25px',
                              width: selectedColor === color ? '21px' : '25px',
                              borderRadius: '50%',
                              backgroundColor: color,
                            }}
                          ></div>
                        </div>
                      );
                    } else return null;
                  })}
                </div>
              )}
            </Container>
          )}

          {(selectedColor !== filters.colorsFetched ||
            periodSelected !== filters.periodFetched ||
            areListsDiff(selectedSourcesArt, filters.sourcesFetchedArt)) && (
            <Container>
              <button onClick={handleApplyFilters} className='applyButton'>
                Apply filters
              </button>
            </Container>
          )}
        </div>
      </section>
    </>
  );
};

export default FiltersArt;
