import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
// Services
import { sendUserSearchInformation } from '../../services/sendUserSearchInformation';
// Styles
import './Card.css';

const Card = ({
  title,
  authors,
  abstract,
  publication_date,
  url,
  type,
  resource_origin,
  peer_reviewed,
}) => {
  const abstractRef = useRef(null);
  // Slices
  const { user_email } = useSelector((state) => state.user);
  const { institutionCode } = useSelector((state) => state.institution);

  // Variables
  const [showFullAbstract, setShowFullAbstract] = useState(false);
  const totalCharacters = 250;

  const concatenatingAuthors = () => {
    return (
      <p className='authorsCard'>
        {Array.isArray(authors) ? authors.join(', ') : ''}
      </p>
    );
  };

  const handleClicCard = () => {
    sendUserSearchInformation(
      title,
      resource_origin,
      url,
      window.location.pathname,
      user_email,
      institutionCode
    );
    window.open(url, '_blank');
  };

  const getAbstract = () => {
    let abstractToShow = showFullAbstract
      ? abstract
      : abstract?.substring(0, totalCharacters) + '...';

    if (showFullAbstract) {
      abstractToShow = abstract;
    } else {
      abstractToShow =
        abstract?.length < totalCharacters
          ? abstract
          : abstract?.substring(0, totalCharacters) + '...';
    }
    return abstractToShow;
  };

  return (
    <div className='containerCard'>
      {title && (
        <p
          className='titleCard'
          onClick={handleClicCard}
          role='button'
          tabIndex='0'
        >
          {title}
        </p>
      )}
      {authors?.length > 0 && concatenatingAuthors()}

      <p
        className={showFullAbstract ? 'textCard textCardFull' : 'textCard'}
        ref={abstractRef}
      >
        {getAbstract()}
      </p>

      {abstract?.length > totalCharacters && (
        <button
          className='buttonAbstract'
          onClick={() => setShowFullAbstract(!showFullAbstract)}
        >
          Show {showFullAbstract ? 'less' : 'more'}
        </button>
      )}

      <div className='infoCard'>
        {type && <p className='category categoryType'>{type}</p>}

        {publication_date && typeof publication_date === 'string' && (
          <p className='category'>
            Published: {publication_date.split('T')[0]}
          </p>
        )}
        {peer_reviewed === true && (
          <p className='category peerReviewed'>Peer reviewed</p>
        )}
      </div>
    </div>
  );
};

export default Card;
