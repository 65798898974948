import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Components
import Container from '../../Components/Container';
import CardArt from '../../Components/CardArt';
import { LoadingSkeleton } from '../../Components/LoadingSkeleton';
import { SkeletonTypes } from '../../Components/LoadingSkeleton/types';
import ArtDetail from '../../Components/ArtDetail';
import FiltersArt from '../../Components/FiltersArt';
import SummarySearch from '../../Components/SummarySearch';
// Hooks
import { useScrollToTop } from '../../hooks/useScrollToTop';
import { useGallery } from '../../hooks/useGallery';
// Slices
import { setPageArt, resetSources } from '../../app/features/artSlice';
// Styles
import './ArtPage.css';

import Pagination from '../../Components/Pagination';

const ArtPage = () => {
  const dispatch = useDispatch();
  // Hooks
  const { handleScrollToTop } = useScrollToTop();
  const { getGalleryDataSocket } = useGallery();
  // Variables
  const { art, totalArt, isLoadingArt, pageArt, selectedDetailArt, filters } =
    useSelector((state) => state.art);
  // Constantes
  const itemsPerPage = 8;
  const indexOfLastItem = pageArt * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = art.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(totalArt / itemsPerPage);

  // Resultados para siguiente pagina
  const indexOfLastItemNext = (pageArt + 1) * itemsPerPage;
  const indexOfFirstItemNext = indexOfLastItemNext - itemsPerPage;
  const nextItems = art.slice(indexOfFirstItemNext, indexOfLastItemNext);

  const handlePageChange = (newPage) => {
    handleScrollToTop(0);
    if (newPage > pageArt) {
      if (
        pageArt < totalPages ||
        (Math.ceil(art?.length / itemsPerPage) > 1 && totalPages === 0)
      ) {
        dispatch(setPageArt(newPage));
        if (nextItems.length < itemsPerPage) {
          dispatch(resetSources());
          getGalleryDataSocket(
            filters.queryFetched,
            filters.pageFetched + 1,
            filters.startYearFetched,
            filters.endYearFetched,
            filters.sourcesFetchedArt,
            filters.colorsFetched,
            filters.onlyImageFetched,
            filters.onlyVideoFetched,
            filters.onlyAudioFetched
          );
        }
      }
    } else {
      if (pageArt > 1) {
        dispatch(setPageArt(newPage));
      }
    }
  };

  return (
    <>
      {Object.keys(selectedDetailArt)?.length > 0 && <ArtDetail />}

      {isLoadingArt && (
        <div className='art'>
          <Container>
            <div className='containerResultsArt'>
              {Array.from({ length: 10 }).map((_, i) => (
                <LoadingSkeleton key={i} type={SkeletonTypes.NEWS} />
              ))}
            </div>
          </Container>
        </div>
      )}

      {art?.length > 0 ? (
        <main className='art'>
          <Container className='artContainer'>
            <section className='containerResultsArt_query'>
              <section className='filtersArtContainer'>
                <SummarySearch total={totalArt} term={filters.queryFetched} />
                <FiltersArt />
              </section>
              <section className='containerResultsArt'>
                {currentItems.map((element, i) => {
                  return <CardArt key={i} position={i} data={element} />;
                })}
              </section>
              <Pagination
                pageShowed={pageArt}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
              />
            </section>
          </Container>
        </main>
      ) : (
        isLoadingArt === false &&
        (totalArt === 0 && filters?.queryFetched?.length === 0 ? (
          <main className='noDataContainer'>
            <p>Please enter a search term</p>
          </main>
        ) : (
          <main className='noDataContainer'>
            <p>No data found</p>
          </main>
        ))
      )}
    </>
  );
};

export default ArtPage;
