import React, { useEffect } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Components
import Container from '../../Components/Container';
// Hooks
import { useScrollToTop } from '../../hooks/useScrollToTop';
// Services
import {
	getElementaryDictionary,
	getMedicalDictionary,
	getThesaurusDictionary,
} from '../../services/getDictionary';
// Slices
import {
	setIsDictionaryDataFetched,
	setElementaryData,
	setIsElementaryDataLoading,
	setMedicalData,
	setIsMedicalDataLoading,
	setThesaurusData,
	setIsThesaurusDataLoading,
	resetDictionaty,
} from '../../app/features/dictionarySlice';
// Styles
import './DictionaryPage.css';

const DictionaryPage = () => {
	const {
		dictionaryQueryFetched,
		medicalData,
		isMedicalDataLoading,
		elementaryData,
		isElementaryDataLoading,
		thesaurusData,
		isThesaurusDataLoading,
	} = useSelector((state) => state.dictionary);
	const { queryFetched } = useSelector((state) => state.library);
	const { isMedicalInstitution } = useSelector((state) => state.institution);

	useEffect(() => {}, [dictionaryQueryFetched]);

	const { handleScrollToTop } = useScrollToTop();
	const dispatch = useDispatch();

	// TODO: Usar esta funcion y reutilizarla en el HOOK y en los lados que se usa
	const changeSearch = (word) => {
		dispatch(resetDictionaty());
		if (isMedicalInstitution) {
			dispatch(setIsMedicalDataLoading(true));
			getMedicalDictionary(word)
				.then((response) => {
					if (typeof response.data[0] !== 'string') {
						let dataMedical = [];
						response.data.map((element) => {
							dataMedical.push({
								meta: element.meta,
								shortdef: element.shortdef,
							});
							return null;
						});
						dispatch(setMedicalData(dataMedical));
					} else {
						dispatch(setMedicalData(response?.data));
					}
					dispatch(setIsMedicalDataLoading(false));
					dispatch(setIsDictionaryDataFetched(true));
				})
				.catch((error) => {
					dispatch(setIsMedicalDataLoading(false));
					console.error('Request error:', error);
				});
		}
		dispatch(setIsThesaurusDataLoading(true));
		getThesaurusDictionary(word)
			.then((response) => {
				if (typeof response.data[0] !== 'string') {
					let dataThesaurus = [];
					response.data.map((element) => {
						dataThesaurus.push({
							meta: element.meta,
							shortdef: element.shortdef,
						});
						return null;
					});
					dispatch(setThesaurusData(dataThesaurus));
				} else {
					dispatch(setThesaurusData(response?.data));
				}
				dispatch(setIsThesaurusDataLoading(false));
				dispatch(setIsDictionaryDataFetched(true));
			})
			.catch((error) => {
				console.error('Request error:', error);
				dispatch(setIsThesaurusDataLoading(false));
			});

		dispatch(setIsElementaryDataLoading(true));
		getElementaryDictionary(word)
			.then((response) => {
				if (typeof response.data[0] !== 'string') {
					let dataElementary = [];
					response.data.map((element) => {
						dataElementary.push({
							meta: element.meta,
							shortdef: element.shortdef,
							history: element.history,
						});
						return null;
					});
					dispatch(setElementaryData(dataElementary));
				} else {
					dispatch(setElementaryData(response?.data));
				}
				dispatch(setIsElementaryDataLoading(false));
				dispatch(setIsDictionaryDataFetched(true));
			})
			.catch((error) => {
				console.error('Request error:', error);
				dispatch(setIsElementaryDataLoading(false));
			});
	};

	const definitionList = (list) => {
		return (
			<ul>
				{list.map((def, i) => (
					<li className='definition' key={i}>
						{def.replace(': as', '')}
					</li>
				))}
			</ul>
		);
	};

	// Definition data from word
	const getDefinitionsList = () => {
		let definitions = [];
		const getDefinition = (data) => {
			data.map((def) => {
				def.shortdef.map((shortdef) => {
					definitions.push(shortdef);
					return null;
				});
				return null;
			});
		};

		typeof medicalData[0] !== 'string' &&
			isMedicalInstitution &&
			getDefinition(medicalData);

		typeof thesaurusData[0] !== 'string' && getDefinition(thesaurusData);
		typeof elementaryData[0] !== 'string' && getDefinition(elementaryData);

		if (definitions.length > 0) {
			return (
				<>
					<h3 className='titleSectionResult' id='definition'>
						Definition
					</h3>
					<ul>
						{Array.from(new Set(definitions)).map((shortdef, i) => (
							<li className='definition' key={i}>
								{shortdef.replace(': as', '')}
							</li>
						))}
					</ul>
				</>
			);
		} else {
			return null;
		}
	};

	// History data from word
	const getHistory = () => {
		let historyList = [];

		if (elementaryData.length > 0) {
			elementaryData.map((element) => {
				if (element.history !== undefined) {
					historyList.push(element.history);
				}
				return null;
			});
			return (
				historyList.length > 0 && (
					<>
						<h3 className='titleSectionResult' id='history'>
							History
						</h3>
						{historyList.map((history, i) => (
							<p className='textSectionResult' key={i}>
								{history.pt[0][1].replace(/\{.*?\}/g, '')}
							</p>
						))}
					</>
				)
			);
		} else {
			return null;
		}
	};

	// Stems data from word
	const getStems = () => {
		let stems = [];
		if (medicalData?.length > 0 && medicalData[0].meta) {
			stems = [...stems, ...medicalData[0].meta.stems];
		}
		if (thesaurusData?.length > 0 && thesaurusData[0].meta) {
			stems = [...stems, ...thesaurusData[0].meta.stems];
		}
		if (elementaryData?.length > 0 && elementaryData[0].meta) {
			stems = [...stems, ...elementaryData[0].meta.stems];
		}

		if (stems?.length > 0) {
			stems = [...new Set(stems)];
			return (
				<>
					<h3 className='titleSectionResult' id='stem'>
						Stem
					</h3>
					{definitionList(stems)}
				</>
			);
		} else {
			return null;
		}
	};

	// Entries Near data from word
	const getEntriesNearList = () => {
		let entriesNearList = [];
		const getEntriesNear = (data) => {
			data.map((entrieNear) => {
				entriesNearList.push(entrieNear);
				return null;
			});
		};

		typeof medicalData[0] === 'string' &&
			isMedicalInstitution &&
			getEntriesNear(medicalData);
		typeof elementaryData[0] === 'string' && getEntriesNear(elementaryData);
		typeof thesaurusData[0] === 'string' && getEntriesNear(thesaurusData);

		if (entriesNearList.length > 0) {
			return (
				<>
					<h3 className='titleSectionResult' id='entriesNear'>
						Entries near
					</h3>
					<div className='entriesNearContainer'>
						{entriesNearList.map((entrieNear, i) => {
							return (
								<button
									className='entrieNearButton'
									key={i}
									onClick={(e) => {
										handleScrollToTop(280);
										e.preventDefault();
										changeSearch(entrieNear);
									}}
								>
									<p>{entrieNear}</p>
								</button>
							);
						})}
					</div>
				</>
			);
		} else {
			return null;
		}
	};

	return (
		<>
			{elementaryData?.length > 0 ||
			medicalData?.length > 0 ||
			thesaurusData?.length > 0 ? (
				<main className='dictionary-main'>
					<Container className='dictionary'>
						<section className='dictionaryNavigation'>
							<div className='dictionaryNavigationContainer'>
								<p className='dictionaryNavigation__title'>
									<b>Content in this word</b>
								</p>
								<div className='dictionaryNavigation__categories'>
									{(elementaryData?.length > 0 && elementaryData[0].meta) ||
									(medicalData?.length > 0 && medicalData[0].meta) ||
									(thesaurusData?.length > 0 && thesaurusData[0].meta) ? (
										<a href='#definition'>
											<p className='contentCategory'>Definition</p>
										</a>
									) : null}

									{elementaryData?.length > 0 &&
										elementaryData[0].meta &&
										elementaryData[0].history !== undefined && (
											<a href='#history'>
												<p className='contentCategory'>History</p>
											</a>
										)}

									{(elementaryData?.length > 0 && elementaryData[0].meta) ||
									(medicalData?.length > 0 && medicalData[0].meta) ||
									(thesaurusData?.length > 0 && thesaurusData[0].meta) ? (
										<a href='#stem'>
											<p className='contentCategory'>Stem</p>
										</a>
									) : null}

									{(elementaryData?.length > 0 &&
										typeof elementaryData[0] === 'string') ||
									(medicalData?.length > 0 &&
										typeof medicalData[0] === 'string') ||
									(thesaurusData?.length > 0 &&
										typeof thesaurusData[0] === 'string') ? (
										<a href='#entriesNear'>
											<p className='contentCategory'>Entries near</p>
										</a>
									) : null}
								</div>
							</div>
						</section>
						<section className='dictionaryResults'>
							<div className='dictionaryResultsContainer'>
								<h2 className='searchWord'>
									{elementaryData?.length > 0 && elementaryData[0].meta
										? elementaryData[0].meta.id.split(':')[0]
										: medicalData?.length > 0 && medicalData[0].meta
										? medicalData[0].meta.id.split(':')[0]
										: thesaurusData?.length > 0 && thesaurusData[0].meta
										? thesaurusData[0].meta.id.split(':')[0]
										: null}
								</h2>
								<p className='wordType'>
									{elementaryData?.length > 0 && elementaryData[0].meta
										? elementaryData[0].fl
										: medicalData?.length > 0 && medicalData[0].meta
										? medicalData[0].fl
										: thesaurusData?.length > 0 && thesaurusData[0].meta
										? thesaurusData[0].fl
										: null}
								</p>

								{getDefinitionsList()}

								{getHistory()}

								{getStems()}

								{getEntriesNearList()}
							</div>
						</section>
					</Container>
				</main>
			) : (
				<div className='noDataContainerDictionary'>
					{isElementaryDataLoading === true ||
					isMedicalDataLoading === true ||
					isThesaurusDataLoading === true ? (
						<span className='loaderDictionary'></span>
					) : queryFetched ? (
						<p className='noDataFound'>No data found</p>
					) : (
						<p>Please enter a search term</p>
					)}
				</div>
			)}
		</>
	);
};

export default DictionaryPage;
