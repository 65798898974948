import { fetchWithoutToken } from '../utils/fetch';

// Este endpoint se usa para noticias bases (Sin busqueda de terminos)
export const getNews = async (institutionCode) => {
	try {
		const res = await fetchWithoutToken.get(
			`/news?institution_code=${institutionCode}`,
			{
				headers: {
					Authorization: `Bearer ${localStorage.token}`,
				},
			}
		);

		return res;
	} catch (error) {
		console.error(error);
	}
};
