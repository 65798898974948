import React from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Components
import Container from '../Container';
import Pagination from '../Pagination';
import SummarySearch from '../SummarySearch';
import CardNewsSearched from '../CardNewsSearched';
import { LoadingSkeleton } from '../../Components/LoadingSkeleton';
import { SkeletonTypes } from '../../Components/LoadingSkeleton/types';
// Slices
import {
  setPageFetched,
  setNews,
  setQueryNewsView,
  setPageShowed,
} from '../../app/features/newsSlice';
// Hooks
import { useScrollToTop } from '../../hooks/useScrollToTop';
// Services
import { getNewsFromQuery } from '../../services/getNewsFromQuery';
// Icons
import { IoMdCloseCircle } from 'react-icons/io';
// Style
import './NewsQuerySection.css';

const NewsQuerySection = () => {
  const dispatch = useDispatch();

  // Hooks
  const { handleScrollToTop } = useScrollToTop();

  // Variables
  const { queryFetched } = useSelector((state) => state.library);
  const { news, total_news_query, pageFetched, is_news_loading, pageShowed } =
    useSelector((state) => state.news);

  // Pagination
  const itemsPerPage = 10;
  const indexOfLastItem = pageShowed * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = news.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(total_news_query / itemsPerPage);
  const totalCurrencyPages = Math.ceil(news?.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    handleScrollToTop(0);
    if (newPage > pageShowed) {
      dispatch(setPageShowed(newPage));
      if (newPage > totalCurrencyPages - 1) {
        dispatch(setPageFetched(pageFetched + 1));
        getNewsFromQuery(queryFetched, pageFetched + 1)
          .then((response) => {
            dispatch(setNews([...news, ...response?.data?.data]));
          })
          .catch((error) => {
            dispatch(setNews([]));
            console.error('Request error:', error);
          });
      }
    } else {
      if (pageShowed > 1) {
        dispatch(setPageShowed(newPage));
      }
    }
  };

  return (
    <Container>
      {is_news_loading && (
        <section className='newsQueryResults loading'>
          {Array.from({ length: 10 }).map((_, i) => (
            <LoadingSkeleton key={i} type={SkeletonTypes.LIBRARY_CARD} />
          ))}
        </section>
      )}

      {news?.length > 0 && (
        <div className='newsQueryContent'>
          <div className='newsQueryContentInfo'>
            <SummarySearch total={total_news_query} />
            <button onClick={() => dispatch(setQueryNewsView(false))}>
              <IoMdCloseCircle className='newsQueryContentIcon' />
            </button>
          </div>

          <section className='newsQueryResults'>
            {currentItems.map((news, i) => {
              return (
                <CardNewsSearched
                  key={i}
                  title={news.title}
                  description={news.description}
                  url={news.url}
                  image_url={news.image_url}
                  publication_date={news.publication_date}
                  resource_origin={news.source}
                />
              );
            })}
          </section>
          <Pagination
            pageShowed={pageShowed}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      )}
    </Container>
  );
};

export default NewsQuerySection;
