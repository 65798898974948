import React, { useEffect, useState } from 'react';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
// Slice
import { setActiveLoginModal } from '../../app/features/userSlice';
import { resetMetasearch } from '../../app/features/metasearchSlice';
import { resetDictionaty as resetDictionatyAction } from '../../app/features/dictionarySlice';
import {
  resetFilters as resetFiltersAction,
  setLibrary as setLibraryAction,
  resetLibrary as resetLibraryAction,
} from '../../app/features/librarySlice';
import {
  setResetNewsQuery as setResetNewsQueryAction,
  setNews as setNewsAction,
} from '../../app/features/newsSlice';
import { resetUserData as resetUserDataAction } from '../../app/features/userSlice';
import { resetPatents as resetPatentsAction } from '../../app/features/patentsSlice';
import { resetJobs as resetJobsAction } from '../../app/features/jobsSlice';
import { resetMultimedia as resetMultimediaAction } from '../../app/features/multimediaSlice';
// Icons
import { FaChevronDown } from 'react-icons/fa';
import closeIcon from '../../Images/Login/LoginPage/close.svg';
// Utils
import { getInitials } from '../../utils/getInitials';
// Style
import './Login.css';

const Login = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentlyRoute = pathname.replace('/', '');

  const { requestLogin } = useSelector((state) => state.institution);
  const { user_email, user_name, user_lastname } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    if (requestLogin) {
      if (
        user_email.length === 0 &&
        user_name.length === 0 &&
        user_lastname.length === 0 &&
        currentlyRoute !== '' &&
        currentlyRoute !== 'login'
      ) {
        dispatch(setActiveLoginModal(true));
        navigate(`/`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isOpenCloseSession, setIsOpenCloseSession] = useState(false);

  const initialsName = () => {
    const initialsFirstName = user_name ? getInitials(user_name) : '';
    const initialsLastName = user_lastname ? getInitials(user_lastname) : '';
    const initials = initialsFirstName + initialsLastName;
    return initials ? initials : 'USER';
  };

  const closeSession = () => {
    setIsOpenCloseSession(false);

    dispatch(resetUserDataAction()); // Reinicia variables de datos de usuario
    dispatch(resetMetasearch()); // Reinicia los estados de Metasearcher
    navigate(`/`);
    // restart all variables
    dispatch(setLibraryAction([])); // Reinicia la data de Library
    dispatch(resetFiltersAction()); // Reinicia Filtros de Library
    dispatch(resetLibraryAction()); // Reinicia estados de Library
    dispatch(resetDictionatyAction()); // Reinicia estados de Dictionary
    dispatch(setResetNewsQueryAction()); // Reinicia estados de News
    dispatch(setNewsAction([])); // Reinicia la data de News
    dispatch(resetPatentsAction()); // Reinicia estados de Patents
    dispatch(resetMultimediaAction()); // Reinicia estados de Multimedia
    dispatch(resetJobsAction()); // Reinicia estados de Jobs

    // Redireccion a Home
    navigate('/');
  };

  const setLogin = () => {
    if (user_email?.length > 0) {
      if (currentlyRoute !== '') {
        return (
          <>
            <div
              className='userLoggedContainer'
              role='button'
              onClick={() => setIsOpenCloseSession(!isOpenCloseSession)}
            >
              <div className='userLogged'>{initialsName()}</div>
              {/* <p>Institution</p> */}
              <FaChevronDown />
            </div>

            {isOpenCloseSession && (
              <button className='closeSession' onClick={closeSession}>
                <p>Sign off</p>
                <img src={closeIcon} alt='close' />
              </button>
            )}
          </>
        );
      } else {
        return null;
      }
    } else {
      return (
        <button
          onClick={() => dispatch(setActiveLoginModal(true))}
          className='loginButton'
        >
          Login
        </button>
      );
    }
  };

  if (requestLogin) {
    return setLogin();
  }
};

export default Login;
