import { formatDate } from '../utils/formatDate';
import { fetchWithoutToken } from '../utils/fetch';
import { getIpUser } from './getIpUser';

export const sendUserSearchInformation = async (
	title,
	resource_origin = '',
	url = '',
	resource_type,
	email,
	institutionCode
) => {
	const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

	if (ENVIRONMENT === 'PRODUCTION') {
		const date = new Date();
		const ip = await getIpUser();

		fetchWithoutToken.post(
			'/access-logs',
			{
				resource_type: resource_type
					? resource_type.replace('/', '')
					: 'No resource type',
				resource_title: title ? title.slice(0, 250) : 'No title',
				resource_origin: resource_origin
					? resource_origin.slice(0, 250)
					: 'No resource origin',
				url: url ? url.slice(0, 250) : 'No url',
				email: email ? email.slice(0, 250) : 'No logged user',
				ip,
				date: formatDate(date),
				institution_code: institutionCode,
			},
			{
				headers: {
					Authorization: `Bearer ${localStorage.token}`,
				},
			}
		);
	} else {
		return null;
	}
};
