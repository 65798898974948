import React, { useRef, useState, useEffect } from 'react';
// Redux
import { useSelector, useDispatch } from 'react-redux';
// Slices
import {
  setApplyJob,
  setViewApplyJobs,
  setJobFetched,
} from '../../app/features/jobsSlice';
// Services
import { getJobsApply } from '../../services/getJobs';
// Images
import { FaChevronDown } from 'react-icons/fa';
import { IoChevronBack } from 'react-icons/io5';
import { IoLocationOutline } from 'react-icons/io5';
import defaultImg from '../../Images/JobsPage/default.png';
import searchJobsIcon from '../../Images/JobsPage/searchjobs.png';
// Styles
import './JobDetail.css';

const JobDetail = () => {
  const divRef = useRef(null);

  const { jobFetched, applyJob, viewApplyJobs } = useSelector(
    (state) => state.jobs
  );
  const [viewMoreDetails, setViewMoreDetails] = useState(false);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTop = 0;
    }
  }, [jobFetched]);

  const dispatch = useDispatch();
  const showDetailJobView = () => {
    if (jobFetched !== null && jobFetched !== undefined) {
      for (var key in jobFetched) {
        if (jobFetched.hasOwnProperty(key)) {
          return true;
        }
      }
    } else {
      return false;
    }
  };

  const showJobsApply = (id) => {
    dispatch(setViewApplyJobs(true));
    getJobsApply(id)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setApplyJob(response.data.data));
        }
      })
      .catch((error) => {
        dispatch(setViewApplyJobs(false));
        console.error('Request error:', error);
      });
  };

  if (showDetailJobView()) {
    return (
      <section
        ref={divRef}
        className='jobDescriptionContainer scroll-containerDetail'
      >
        <section className='jobDescriptionContainer__summary'>
          <button
            className='backButton'
            onClick={() => dispatch(setJobFetched({}))}
          >
            <IoChevronBack />
            Back
          </button>
          <div className='jobDescriptionContainer_img_content'>
            <div className='jobDescriptionContainer__content'>
              <img
                alt='IMG'
                src={jobFetched.thumbnail ? jobFetched.thumbnail : defaultImg}
              />
              <div className='contentInfo'>
                <h2>{jobFetched.title}</h2>
                <div>
                  <p className='jobDescriptionContainer__description time'>
                    {jobFetched.detected_extensions?.schedule_type}
                    {jobFetched.detected_extensions?.work_from_home &&
                      ', remote'}
                  </p>
                  {jobFetched.detected_extensions?.posted_at && (
                    <p className='jobDescriptionContainer__description publication'>
                      Published: {jobFetched.detected_extensions?.posted_at}
                    </p>
                  )}
                </div>

                <div>
                  <h3 className='jobDescriptionContainer__company'>
                    {jobFetched.company_name}
                  </h3>
                  <p className='jobDescriptionContainer__description'>
                    <IoLocationOutline className='locationIcon' />
                    {jobFetched.location}
                  </p>
                </div>
                <p>{jobFetched.detected_extensions?.salary}</p>
              </div>
            </div>
            {!viewApplyJobs && (
              <button
                className='viewJobButton'
                onClick={() => showJobsApply(jobFetched.job_id)}
              >
                Apply to job
              </button>
            )}
          </div>

          {viewApplyJobs && (
            <div className='applyJobsContainer'>
              <p>Apply for this vacancy here:</p>
              {applyJob?.length === 0 && <span className='loader'></span>}
              <div className='applyJobOptions'>
                {applyJob?.map((apply, i) => {
                  return (
                    <a
                      key={i}
                      href={apply.link}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {apply.title}
                    </a>
                  );
                })}
              </div>
            </div>
          )}
        </section>

        {/* Sección de detalle completo */}
        <section className='jobDescriptionContainer__detail'>
          {jobFetched?.job_highlights?.length > 0 && (
            <section className='experienceSection'>
              {jobFetched?.job_highlights?.map((highlight, i) => (
                <div key={i}>
                  <h3 className='jobDescriptionContainer__detailTitle'>
                    {highlight.title}:
                  </h3>
                  <ul>
                    {highlight.items.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </section>
          )}

          <section className='summarySection'>
            <h3 className='jobDescriptionContainer__detailTitle'>
              Employment Summary
            </h3>
            <p>
              Job: <span>{jobFetched.title}</span>
            </p>

            {jobFetched.detected_extensions?.schedule_type && (
              <p>
                Working time:{' '}
                <span>{jobFetched.detected_extensions.schedule_type}</span>
              </p>
            )}

            {jobFetched.detected_extensions?.work_from_home && (
              <p>
                Workplace:{' '}
                <span>
                  {jobFetched.detected_extensions.work_from_home && 'Remote'}
                </span>
              </p>
            )}

            {jobFetched.detected_extensions?.salary && (
              <p>
                Salary: <span>{jobFetched.detected_extensions?.salary}</span>
              </p>
            )}
          </section>

          <section className='moreDetailsSection'>
            <div>
              <button
                className='viewMoreDetailsButton'
                onClick={() => {
                  if (viewMoreDetails) {
                    window.scrollTo(0, 0);
                    window.scrollTo(0, 400);
                  }
                  setViewMoreDetails(!viewMoreDetails);
                }}
              >
                View {viewMoreDetails ? 'less' : 'more'} details{' '}
              </button>
              <FaChevronDown
                className={
                  viewMoreDetails
                    ? 'viewMoreDetailsButton iconActive'
                    : 'viewMoreDetailsButton'
                }
              />
            </div>

            {viewMoreDetails && (
              <div className='moreDetailsDescription'>
                <pre>{jobFetched.description}</pre>
              </div>
            )}
          </section>
        </section>
      </section>
    );
  } else {
    return (
      <div className='searchJobsDetail'>
        <img src={searchJobsIcon} alt='search jobs icon' />
        <p>To view details, select the job you are interested in</p>
      </div>
    );
  }
};

export default JobDetail;
