import React, { useEffect, useState } from 'react';
// Redux
import { setSelectedDetailArt } from '../../app/features/artSlice';
import { useDispatch, useSelector } from 'react-redux';
// Icons
import { IoMdClose } from 'react-icons/io';
import default_noData from '../../Images/ArtPage/default_noData.jpg';
// Styles
import './ArtDetail.css';

const ArtDetail = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagenPorDefectoCargada, setImagenPorDefectoCargada] = useState(false);

  const { selectedDetailArt } = useSelector((state) => state.art);
  const images = selectedDetailArt?.images;

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImagenPorDefectoCargada(true);
    };
    img.src = selectedImage;
  }, [selectedImage]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (images && images.length > 0) {
      setSelectedImage(images[0]);
    }
  }, [images]);

  const get_authors = (authors_list) => {
    let concatened_authors_list = [];
    authors_list.map((artist) => {
      let artist_info = '';
      if (artist.name) {
        artist_info += artist.name;
      }
      if (artist.date) {
        artist_info += ` (${artist.role})`;
      }
      concatened_authors_list.push(artist_info);
      return null;
    });
    return concatened_authors_list;
  };

  return (
    <div className='artDetail modal-containerArt'>
      <div className='modal-contenidoArt'>
        <div className='modal-contenidoArt-content'>
          {selectedDetailArt?.images?.length > 0 && (
            <div className='imageDetailContainer'>
              <div className='imageDetailContainer_big'>
                {/* <a
                  target='_blank'
                  rel='noreferrer'
                  href={selectedDetailArt.url}
                > */}
                  <img
                    alt='principal view from work'
                    className='principalImage'
                    src={
                      imagenPorDefectoCargada ? selectedImage : default_noData
                    }
                  />
                {/* </a> */}
              </div>
              {selectedDetailArt?.images?.length > 1 && (
                <div className='imageDetailContainer_small'>
                  {images?.map((image, i) => {
                    return (
                      <img
                        key={i}
                        alt='small view from work'
                        className={
                          selectedImage === image
                            ? 'secondaryImages secondaryImagesSelected'
                            : 'secondaryImages'
                        }
                        role='button'
                        onClick={() => setSelectedImage(image)}
                        src={image}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          )}

          <div className='descriptionDetailContainer'>
            <h2 className='detail_title'>{selectedDetailArt?.title}</h2>
            {selectedDetailArt?.artist?.length > 0 && (
              <div>
                <p>
                  <b>Artist{selectedDetailArt.artist?.length > 1 && '(s)'}</b>
                </p>
                {get_authors(selectedDetailArt.artist).map((artist, i) => {
                  return <p key={i}>{artist}</p>;
                })}
              </div>
            )}

            {selectedDetailArt?.description && (
              <p className={'detail_description'}>
                <b className=''>Description:</b> {selectedDetailArt.description}
              </p>
            )}

            {selectedDetailArt?.date && (
              <p>
                <b>Year of work: {selectedDetailArt.date}</b>
              </p>
            )}

            {selectedDetailArt?.location && (
              <p>
                <b>Location</b> {selectedDetailArt.location}
              </p>
            )}

            {selectedDetailArt?.url && (
              <p>
                <b>URL detail:</b>{' '}
                <a
                  target='_blank'
                  rel='noreferrer'
                  href={selectedDetailArt.url}
                >
                  Link
                </a>
              </p>
            )}

            {selectedDetailArt?.object && (
              <p>
                <b>Type:</b> {selectedDetailArt.object}
              </p>
            )}

            {selectedDetailArt?.dimensions && (
              <p>
                <b>Dimensions:</b> {selectedDetailArt.dimensions}
              </p>
            )}

            {selectedDetailArt?.materials && (
              <p>
                <b>Materials:</b> {selectedDetailArt.materials}
              </p>
            )}
          </div>
        </div>

        <div>
          <button onClick={() => dispatch(setSelectedDetailArt({}))}>
            <IoMdClose />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ArtDetail;
