import React from 'react';
// Components
import Container from '../Container';
import Navbar from '../Navbar';
// Images
import logoAquinas from '../../Images/logo/logo_Aquinas.png';
import logoOnlineLibrary from '../../Images/logo/logo_OlibBlack.svg';
// Styles
import './Footer.css';

const Footer = () => {
  return (
    <footer>
      <Container className='footerContainer'>
        <img src={logoOnlineLibrary} alt='logo Aquinas Network' />
        <Navbar type='black' />
      </Container>

      <section className='rightsSection'>
        <Container className='rightsSectionContainer'>
          <p>
            All rights reserved <b>Olib 2024 ®</b>
          </p>
          <div>
            <p>Powered by</p>
            <img src={logoAquinas} alt='logo Aquinas Network' />
          </div>
        </Container>
      </section>
    </footer>
  );
};

export default Footer;
