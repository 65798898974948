import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  books: [],
  totalBooks: 0,
  isBooksDataFetched: false,
  queryFetched: '',
  booksSources: [],
  allBooksSources: [],
  selectedSources: [],
  booksKeywords: [],
  selectedKeywords: [],
  isBooksLoading: false,
  isBooksLoadingMoreData: false,
  startYear: 1990,
  endYear: 2024,
  pageShowed: 1,
  filters: {
    queryFetched: '',
    sourcesFetched: [],
    keywordsFetched: [],
    startYearFetched: 1990,
    endYearFetched: 2024,
    pageFetched: 1,
  },
};

const booksSlice = createSlice({
  name: 'books',
  initialState,
  reducers: {
    // Toda la data de Books
    setBooks: (state, action) => {
      state.books = [...state.books, ...action.payload];
    },
    // Total de resultados de Books
    setTotalBooks: (state, action) => {
      state.totalBooks = action.payload;
    },
    // Vaciar datos de Books
    resetBooks: (state) => {
      state.books = [];
      state.totalBooks = 0;
      state.booksSources = [];
    },
    // Estado para loading de carga de data
    setIsBooksLoading: (state, action) => {
      state.isBooksLoading = action.payload;
    },
    setIsBooksLoadingMoreData: (state, action) => {
      state.isBooksLoadingMoreData = action.payload;
    },
    // Todas las bases de datos de Books
    setBooksSources: (state, action) => {
      // state.booksSources = [...state.booksSources, action.payload];

      const booksSources = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];
      state.booksSources = [...state.booksSources, ...booksSources];
    },
    // Bases de datos seleccionadas
    setSelectedSources: (state, action) => {
      state.selectedSources = action.payload;
    },
    // Total de keywords de Books
    setBooksKeywords: (state, action) => {
      state.booksKeywords = action.payload;
    },
    // Keywords seleccionadas
    setSelectedKeywords: (state, action) => {
      state.selectedKeywords = action.payload;
    },
    setIsBooksDataFetched: (state, action) => {
      state.isBooksDataFetched = true;
      state.queryFetched = action.payload;
    },
    setStartYear: (state, action) => {
      state.startYear = action.payload;
    },
    setEndYear: (state, action) => {
      state.endYear = action.payload;
    },
    setPageShowed: (state, action) => {
      state.pageShowed = action.payload;
    },
    resetBooksSources: (state) => {
      state.booksSources = [];
    },
    resetBooksKeywords: (state) => {
      state.booksKeywords = [];
    },
    setAllBooksSources: (state, action) => {
      state.allBooksSources = action.payload;
    },

    // Reinicio de filtros
    resetFilters: (state) => {
      state.books = [];
      state.totalBooks = 0;
      state.isBooksDataFetched = false;
      state.selectedSources = [];
      state.selectedKeywords = [];
      state.startYear = 1990;
      state.endYear = 2024;
      state.pageShowed = 1;
      state.isBooksLoading = false;
      state.isBooksLoadingMoreData = false;
    },

    // Filtros para datos buscados (Fetched)
    setQueryFetched: (state, action) => {
      state.filters.queryFetched = action.payload;
    },
    setSourcesFetched: (state, action) => {
      state.filters.sourcesFetched = action.payload;
    },
    setKeywordsFetched: (state, action) => {
      state.filters.keywordsFetched = action.payload;
    },
    setStartYearFetched: (state, action) => {
      state.filters.startYearFetched = action.payload;
    },
    setEndYearFetched: (state, action) => {
      state.filters.endYearFetched = action.payload;
    },
    setPageFetched: (state, action) => {
      state.filters.pageFetched = action.payload;
    },
  },
});

export const {
  setBooks,
  setShowInstructions,
  resetBooks,
  setIsBooksLoading,
  setIsBooksLoadingMoreData,
  setTotalBooks,
  setIsBooksDataFetched,
  setBooksSources,
  resetBooksSources,
  setSelectedSources,
  resetBooksKeywords,
  setBooksKeywords,
  setSelectedKeywords,
  setResetFilters,
  setStartYear,
  setEndYear,
  // Pagination
  setPageShowed,
  setAllBooksSources,
  // Reset
  resetFilters,
  // Filtros para datos buscados
  setQueryFetched,
  setSourcesFetched,
  setKeywordsFetched,
  setStartYearFetched,
  setEndYearFetched,
  setPageFetched,
} = booksSlice.actions;

export default booksSlice.reducer;
